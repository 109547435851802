body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  font-size: 10px !important;
  width: 300px !important;
  color: white !important;
}